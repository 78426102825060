import React, { useState, useEffect } from 'react';
import { showToast, ToastType } from '../../utils/ToastUtil';
import { useToast } from '../../context/Toast/ToastProvider';
import TurnList from './TurnList';
import VirtualGuard from './VirtualGuard';
import LiquidationList from './LiquidationList';
import VideoCallProfesional from '../../components/AgoraVideoCall/videoCallProfesional';
import { RefreshPage } from '../../utils/RefreshPageUtil';
import { CheckPermission } from '../../utils/CheckDesktopPermissions';
import ProfessionalDashboardReducer, {
  initialState,
} from '../../context/profesionalPage/professionalDashboardReducer';
import { ProfessionalDashboardState } from '../../context/profesionalPage/profesionalDashboardState';
import { Box, CircularProgress } from "@material-ui/core";
import ActiveProfessionalsList from './ActiveProfessionalsList/activeProfessionalsList';
const Professional = () => {
  const toastDispatch = useToast();
  const [videoCall, setVideoCall] = useState(false);
  const [hasPermission, setHasPermission] = useState(false);
  const [askPermission, setAskPermission] = useState(false);
  const [virtualGuardData, setVirtualGuardData] = useState([]);
  const [toastMessage, setToastMessage] = useState('');
  const [onConsult, setOnConsult] = useState(false);
  const [onConsultStyle, setOnConsultStyle] = useState({});

  useEffect(() => {
    if (toastMessage !== '') {
      showToast(toastDispatch, toastMessage, ToastType.INFO, ToastType.INFO);
    }
  }, [toastMessage]);

  useEffect(() => {
    if (onConsult === true) {
      setOnConsultStyle({ background: 'rgba(0, 0, 0, 0.3)' });
    } else {
      setOnConsultStyle({});
    }
  }, [onConsult]);
  const [reLoading, setreLoading] = useState(false);
  const [isAllowed, setIsAllowed] = useState(false);

  const realoadComponent = () => {
    setreLoading(true);
    setTimeout(() => {
      setreLoading(false);
    }, 2000); // Genera una nueva clave aleatoria
  };
   let REACT_APP_PROFESSIONALS_LIST =
    process.env.REACT_APP_PROFESSIONALS_LIST === "true";
  return (
    <ProfessionalDashboardState
      initialState={initialState}
      reducer={ProfessionalDashboardReducer}
    >
      <RefreshPage />
      <CheckPermission setHasPermission={setHasPermission} askPermission={askPermission} setAskPermission={setAskPermission}/>
      {videoCall ? (
        <VideoCallProfesional
          videoCallData={virtualGuardData}
          setVideoCall={setVideoCall}
          setMessage={setToastMessage}
        />
      ) : (
        <div className="professional" style={onConsultStyle}>
          <div className="column">
            {
              //<TurnList />
            }
          </div>
          <div
            className="column"
            style={onConsult ? { background: 'white' } : {}}
          >
            {reLoading ? (
              <Box
                width={"100%"}
                justifyContent={"center"}
                alignItems={"center"}
                display={"flex"}
              >
                <CircularProgress />
              </Box>
            ) : (
              <VirtualGuard
                setVirtualGuardData={setVirtualGuardData}
                setVideoCall={setVideoCall}
                toastMessage={toastMessage}
                setOnConsult={setOnConsult}
                onConsult={onConsult}
                hasPermission={hasPermission}
                setAskPermission={setAskPermission}
                realoadComponent={realoadComponent}
                setIsAllowed={setIsAllowed}
                isAllowed={isAllowed}
              />
            )}
          </div>
          <div className="column">
              {/* <LiquidationList /> */}
            {REACT_APP_PROFESSIONALS_LIST &&
              <ActiveProfessionalsList />
            }
          </div>
          
        </div>
      )}
    </ProfessionalDashboardState>
  );
};

export default Professional;
