import React, {
  createContext,
  useEffect,
  useState,
  useContext,
  useRef,
} from "react";
import { useStateAuthValue } from "../../context/Auth/AuthState";
import { GetSessionToken, ProfessionalLog } from "../../services/authService";
import { ToastType, showToast } from "../../utils/ToastUtil";
import { useToast } from "../../context/Toast/ToastProvider";
import SockJS from "sockjs-client";
import { over } from "stompjs";
import { useProfessionalLogState } from "../../context/profesionalPage/professionalLog/professionalLogState";

export const ProfessionalLogHook = () => {
  const [{ userData, userToken }] = useStateAuthValue();
  const [{ stompClient }, dispatch] = useProfessionalLogState();
  const toastDispatch = useToast();
  const showError = (msg) =>
    showToast(toastDispatch, msg, ToastType.ERROR, ToastType.ERROR);
  const showSucces = (msg) =>
    showToast(toastDispatch, msg, ToastType.SUCCESS, ToastType.SUCCESS);

  useEffect(() => {
    if (stompClient != null) {
      console.log("stompClient.connected: ", stompClient.connected);
    } else if (userData?.email) {
      dispatch({
        type: "CONNECT_WS",
        payload: {
          mail: userData.email,
          userData,
          message: {
            professionalId: userData.email,
            command: "InitialPong",
          },
        },
      });
    }
  }, [stompClient, userData]);
  return <div id="professional-log" style={{ position: "absolute" }}></div>;
};

export const connectToWSProfessionalLog = (email, userData, dispatch) => {
  dispatch({
    type: "CONNECT_WS",
    payload: {
      mail: email,
      userData,
      message: {
        professionalId: userData.email,
        command: "LogInPong",
      },
    },
  });

};
